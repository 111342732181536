import React, { FC, useContext } from "react";
import { graphql, Link } from "gatsby";
import { EventsQuery } from "../../graphql-types";
import { Blocks } from "../components/blocks";
import {
  colors,
  spaceRem,
  targetBaseFontSizePx,
  wideCenterRem,
  fontStacks,
} from "../global-style";
import { LayoutContext } from "../components/layout";
import { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";
import { Arrow } from "../components/arrow";
import { useLocation } from "@reach/router";

export const query = graphql`
  query Events($id: String) {
    header: file(relativePath: { eq: "news-events-template-header.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    wpPage(id: { eq: $id }) {
      title
    }

    allWpEvent(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          date
          uri
          language {
            slug
          }
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          eventCustomFields {
            eventType
            eventDate
            eventLocation
            eventPeriod {
              eventPeriodEndDate
              eventPeriodStartDate
            }
          }
        }
      }
    }
  }
`;

const Events: FC<{ data: EventsQuery }> = props => {
  const { remInPx } = useContext(LayoutContext);

  const location = useLocation();
  const currentLanguage = location.pathname.split("/").filter(v => v)[0] as
    | "nl"
    | "en";

  return (
    <>
      <div style={{ position: "relative" }}>
        <Img
          fluid={props.data.header?.childImageSharp?.fluid as FluidObject}
          style={{ minHeight: "50vh" }}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              marginTop: "auto",
              hyphens: "none",
              maxWidth: "30ch",
              textAlign: "center",
              color: colors.white,
              paddingLeft: `${0.5 * spaceRem}rem`,
              paddingRight: `${0.5 * spaceRem}rem`,
              marginBottom: `${0.5 * spaceRem}rem`,
            }}
            css={(() => {
              const minFontSizeRem = 1.8888;
              const maxFontSizeRem = 3;
              const vwRangeStartPx = 1000;

              return css`
                font-size: ${(minFontSizeRem *
                  (remInPx || targetBaseFontSizePx)) /
                  (vwRangeStartPx / 100)}vw;

                @media (max-width: ${vwRangeStartPx}px) {
                  font-size: ${minFontSizeRem}rem;
                }

                @media (min-width: ${vwRangeStartPx *
                    (maxFontSizeRem / minFontSizeRem)}px) {
                  font-size: ${maxFontSizeRem}rem;
                }
              `;
            })()}
            dangerouslySetInnerHTML={{
              __html: props.data.wpPage?.title || "",
            }}
          />

          <button
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              border: "none",
              borderRadius: "50%",
              height: `${1.5 * spaceRem}rem`,
              width: `${1.5 * spaceRem}rem`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.white,
              cursor: "pointer",
              outline: "none",
            }}
            onClick={() => {
              scrollTo({
                top:
                  typeof window !== "undefined" ? window.innerWidth * 0.4 : 0,
                behavior: "smooth",
              });
            }}
          >
            <Arrow direction="down" />
          </button>

          <div
            style={{
              width: 1,
              backgroundColor: colors.mediumBlue,
              opacity: 0,
              height: "26vw",
            }}
          />
        </div>
      </div>

      <div style={{ backgroundColor: colors.darkestBlue }}>
        <div
          style={{
            maxWidth: `${wideCenterRem}rem`,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            top: "-3vw",
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
            paddingBottom: "3vw",
          }}
          css={`
            > * {
              margin-left: auto;
              margin-right: auto;
            }

            > *:not(:last-child) {
              margin-bottom: ${spaceRem}rem;
            }

            @media (min-width: 1200px) {
              > *:nth-child(odd) {
                margin-left: 0;
              }

              > *:nth-child(even) {
                margin-right: 0;
                position: relative;
                top: 8rem;
              }
            }
          `}
        >
          {props.data.allWpEvent?.edges
            ?.filter(edge => edge?.node?.language?.slug === currentLanguage)
            .map((edge, i) => (
              <div
                key={i}
                style={{
                  flexBasis: "31rem",
                  flexGrow: 1,
                  maxWidth: "31rem",
                }}
              >
                <Link to={edge?.node?.uri}>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                    }}
                  >
                    <Img
                      fluid={
                        edge?.node?.featuredImage?.node?.localFile
                          ?.childImageSharp?.fluid as FluidObject
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: colors.white,
                      padding: `${0.5 * spaceRem}rem`,
                    }}
                  >
                    <p
                      style={{
                        fontFamily: fontStacks.poppins,
                        fontWeight: 600,
                        fontSize: "0.6rem",
                        textTransform: "uppercase",
                        color: colors.mediumBlue,
                        letterSpacing: 2,
                      }}
                    >
                      {new Date(
                        (edge?.node?.eventCustomFields?.eventType ===
                          "single_date" &&
                          edge.node.eventCustomFields.eventDate?.replace(
                            /(\d{2})\/(\d{2})\/(\d{4})/,
                            "$2/$1/$3"
                          )) ||
                          edge.node?.eventCustomFields?.eventPeriod?.eventPeriodStartDate?.replace(
                            /(\d{2})\/(\d{2})\/(\d{4})/,
                            "$2/$1/$3"
                          ) ||
                          ""
                      ).toLocaleDateString(currentLanguage, {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}

                      {edge?.node?.eventCustomFields?.eventType === "period"
                        ? `– ${new Date(
                            edge.node?.eventCustomFields?.eventPeriod?.eventPeriodEndDate?.replace(
                              /(\d{2})\/(\d{2})\/(\d{4})/,
                              "$2/$1/$3"
                            ) || ""
                          ).toLocaleDateString(currentLanguage, {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}`
                        : null}

                      {edge?.node?.eventCustomFields?.eventLocation &&
                        ` — ${edge.node.eventCustomFields.eventLocation}`}
                    </p>
                    <p
                      style={{
                        fontFamily: fontStacks.poppins,
                        fontWeight: 600,
                        fontSize: "1.1rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: edge?.node?.title || "",
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>

      {/* </BackgroundImage> */}

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </>
  );
};

export default Events;
